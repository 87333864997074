import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import {
  CAMPING_FETCH,
 // CAMPING_DELETE,
  CAMPING_SAVE,
  CAMPING_CREATE,
} from "./actions.type";

import { CAMP_SET_ALL, CAMP_SAVE } from "./mutations.type";

const initialState = {
  campingplaces: [],
};

export const state = { ...initialState };

const actions = {
  [CAMPING_FETCH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("camping/getall")
        .then(({ data }) => {
          context.commit(CAMP_SET_ALL, data);
          return data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },

  [CAMPING_SAVE](context, campdata) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      let dataJ = JSON.stringify({
        shortname: campdata.shortname,
        placename: campdata.placename,
        city: campdata.city,
        password: campdata.password,
      });
      return new Promise((resolve) => {
        ApiService.post("camping/update", dataJ)
          .then(({ data }) => {
            //context.commit(CAMP_SAVE, data);
            console.log(data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  },

  [CAMPING_CREATE](context, campdata) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      let dataJ = JSON.stringify({
        shortname: campdata.shortname,
        placename: campdata.placename,
        city: campdata.city,
        password: campdata.password,
      });
      return new Promise((resolve) => {
        ApiService.post("camping/create", dataJ)
          .then(({ data }) => {
            //context.commit(CAMP_SAVE, data);
            console.log(data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  },
};

const mutations = {
  [CAMP_SET_ALL](state, campingplaces) {
    state.campingplaces = campingplaces;
  },
  [CAMP_SAVE](state, campdata) {
    state.campingplaces = campdata;
  },
};

const getters = {
  getCampingplaces(state) {
    return state.campingplaces;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
