import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import "./registerServiceWorker";
import VueHtmlToPaper from "vue-html-to-paper";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import { CHECK_AUTH } from "./store/actions.type";
import ApiService from "./common/api.service";
import DateFilter from "./common/date.filter";
import ErrorFilter from "./common/error.filter";
//import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.filter("date", DateFilter);
Vue.filter("error", ErrorFilter);
Vue.use(Vuetify);
Vue.use(VueHtmlToPaper, options);

ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) =>
  Promise.all([store.dispatch(CHECK_AUTH)]).then(next)
);

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount("#app");

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=no',
    'scrollbars=yes'
  ],
  styles: [
    `/styles/main.css`
  ]
}
