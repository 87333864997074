import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { BOOKING_VOUCHER, BOOKING_VOUCHER_RESET } from "./actions.type";

import { SET_VOUCHER, RESET_VOUCHER } from "./mutations.type";

const initialState = {
  voucherData: {
    username: "",
    password: "",
    expireDays: -1,
  },
};

export const state = { ...initialState };

const actions = {
  [BOOKING_VOUCHER](context, bookingInfo) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(
        "newuser/" + bookingInfo.days + "/" + bookingInfo.username
      )
        .then(({ data }) => {
          context.commit(SET_VOUCHER, data);
          state.voucherData.expireDays = +bookingInfo.days;
          return data;
        })
        .catch((error) => {
          context.commit(RESET_VOUCHER);
          console.log(error);
        });
    }
  },
  [BOOKING_VOUCHER_RESET](context) {
    context.commit(RESET_VOUCHER);
  },
};

const mutations = {
  [SET_VOUCHER](state, voucher) {
    state.voucherData = voucher;
  },
  [RESET_VOUCHER](state) {
    state.voucherData.username = "";
    state.voucherData.password = "";
    state.voucherData.expireDays = -1;
  },
};

const getters = {
  voucherData(state) {
    return state.voucherData;
  },
  showPrintout(state) {
    if (state.voucherData.username == "") {
      return true;
    } else {
      return false;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
