<template>
  <nav class="navbar navbar-light">
    <div class="container">
      <router-link class="navbar-brand" :to="{ name: 'home' }">
        <img alt="finkk logo" src="../assets/flinkk-logo-xs.png" />
      </router-link>
      <ul v-if="isAuthenticated" class="nav navbar-nav pull-xs-right">
        <li v-if="isAdmin" class="nav-item">
          <router-link
            class="nav-link"
            active-class="active"
            exact
            :to="{ name: 'admin' }"
          >
            Admin
          </router-link>
        </li>
        <li v-if="isAdmin" class="nav-item">
          <router-link
            class="nav-link"
            active-class="active"
            exact
            :to="{ name: 'reporting' }"
          >
            Reporting
          </router-link>
        </li>
        <li class="nav-item">
          <button
            v-on:click="logout"
            class="btn btn-lg btn-primary pull-xs-right"
          >
            Abmelden
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/store/actions.type";

export default {
  name: "RwvHeader",
  computed: {
    ...mapGetters([
      "currentUser",
      "isAuthenticated",
      "getCampingplaces",
      "isAdmin",
    ]),
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
    },
  },
};
</script>
