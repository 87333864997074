import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { REPORTING_FETCH } from "./actions.type";

import { REPORTING_SET_ALL } from "./mutations.type";

const initialState = {
  reportings: [],
};

export const state = { ...initialState };

const actions = {
  [REPORTING_FETCH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise((resolve) => {
        ApiService.get("reporting/getall")
          .then(({ data }) => {
            context.commit(REPORTING_SET_ALL, data);
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  },
};

const mutations = {
  [REPORTING_SET_ALL](state, reportings) {
    state.reportings = reportings;
  },
};

const getters = {
  getReportings(state) {
    return state.reportings;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
