import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { LOGIN, LOGOUT, CHECK_AUTH, UPDATE_USER } from "./actions.type";
import { SET_AUTH, PURGE_AUTH, SET_ERROR } from "./mutations.type";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isAdmin(state) {
    return state.user.roles[0] === "ROLE_ADMIN";
  },
};

const actions = {
  [LOGIN](context, credentials) {
    let dataJ = JSON.stringify({
      password: credentials.password,
      username: credentials.username,
    });
    return new Promise((resolve) => {
      ApiService.post("login", dataJ)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.status);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [CHECK_AUTH](context) {
    if (state.isAuthenticated && !Object.keys(state.user).length == 0) {
      return true;
    } else {
      context.commit(PURGE_AUTH);
      return false;
    }
  },
  [UPDATE_USER](context, payload) {
    const { usershortname, username, password } = payload;
    const user = {
      usershortname,
      username,
    };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data.user);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, status) {
    console.log("Set error state: " + status);
    state.errors = { error: "Anmeldung nicht erfolgreich." };
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.jwt);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
