<template>
  <footer>
    <div class="container">
      <router-link class="logo-font" :to="{ name: 'home', params: {} }">
        MIKO-NET
      </router-link>
      <span class="attribution">
        Das Voyager-Buchungsystem für Ihren Campingplatz von
        <a rel="noopener noreferrer" target="blank" href="https://flinkk.net/"
          >flinkk</a
        >.
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "RwvFooter",
};
</script>
