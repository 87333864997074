<template>
  <div class="auth-page">
    <div v-if="!isAuthenticated" class="container page">
      <div class="row">
        <div class="col-md-6 offset-md-3 col-xs-12">
          <h1 class="text-xs-center">Anmeldung</h1>
          <ul v-if="errors" class="error-messages">
            <li v-for="(v, k) in errors" :key="k">{{ v }}</li>
          </ul>
          <form @submit.prevent="onSubmit(username, password)">
            <fieldset class="form-group">
              <input
                class="form-control form-control-lg"
                type="text"
                v-model="username"
                placeholder="Campingplatz (kurz)"
              />
            </fieldset>
            <fieldset class="form-group">
              <input
                class="form-control form-control-lg"
                type="password"
                v-model="password"
                placeholder="Passwort"
              />
            </fieldset>
            <button class="btn btn-lg btn-primary pull-xs-right">
              Anmelden
            </button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="isAuthenticated" class="container page">
      Leider konnte die Buchungsseite nicht angezeigt werden. Bitte versuchen
      Sie sich erneut anzumelden oder wenden Sie sich an den technischen
      Support.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { LOGIN } from "@/store/actions.type";

export default {
  name: "RwvLogin",
  data() {
    return {
      username: null,
      password: null,
    };
  },
  methods: {
    onSubmit(username, password) {
      this.$store.dispatch(LOGIN, { username, password }).then((response) => {
        console.log("Check admin response : " + response);
        if (this.isAdmin) {
          this.$router.push({ name: "reporting" });
        } else {
          this.$router.push({ name: "home" });
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "getCampingplaces",
      "currentUser",
      "isAdmin",
    ]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
