import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import booking from "./booking.module";
import managecamping from "./managecamping.module";
import reporting from "./reporting.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    booking,
    managecamping,
    reporting,
  },
});
