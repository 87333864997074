<template>
<v-app id ="app">
  <div id="app">
    <RwvHeader />  
      <div v-if="!isAuthenticated">  
        <Login />  
      </div>       
      <div v-if="isAuthenticated"> 
        <router-view></router-view>
      </div>
    <RwvFooter />
  </div>
  </v-app>
</template>

<script>
import RwvHeader from "@/components/TheHeader";
import RwvFooter from "@/components/TheFooter";
import Login from "@/views/Login";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    RwvHeader,
    RwvFooter,    
    Login
  },
  computed: {
    ...mapGetters(["isAuthenticated","getCampingplaces","currentUser","getReportings","isAdmin"])
    }
};
</script>

<style>
  @import './assets/main.css';
</style>
