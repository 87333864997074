import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: () => import("@/views/Login"),
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/views/Login"),
    },
    {
      name: "home",
      path: "/home",
      component: () => import("@/views/Home"),
    },
    {
      name: "admin",
      path: "/admin",
      component: () => import("@/views/Admin"),
    },
    {
      name: "reporting",
      path: "/reporting",
      component: () => import("@/views/Reporting"),
    },
  ],
});
